import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'





export default function Strategia() {

    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/kogym/4/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                        
                    ...GatsbyImageSharpFluid
                    }
                }
                },

               duza: file(relativePath: { eq: "projekty/kogym/4/duza@2x.jpg" },
               sourceInstanceName: {
                   eq: "images"
                 }) {
                        childImageSharp {
                            fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        },    












                            
            }
            `)
    
    return(
        <>

      

        <Rozdzial tytul="FOTOGRAFIA"  tekst="" nr="04." img={data.okladka.childImageSharp.fluid} tekst="Niewielka sesja studyjna pozwoliła nam na realizację  wszystkich niezbędnych treści do materiałów mediowych. Dzięki temu są one spójne i tworzą jednolite i ciekawe doświadczenie." invert={true}>
        <TytulPunkty tekst={["W ramach projektu stworzyliśmy materiały mediowe, które pomogą  ekipie Mariusza w podstawowej obecności w mediach społecznościowych. Reszta contentu Mariusza tworzona jest real-life - więcej na ", <a href="https://www.instagram.com/mcieslinski/?hl=en" target="_blank">Instagramie @mcieslinski.</a>,  ""]} />

        

        <Img fluid={data.duza.childImageSharp.fluid} />

       
        

        

        
        
        </Rozdzial>
        
        </>
    )
}