import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Punkt from '../comp/punkt_flex'
import Rozdzial from '../comp/rozdzial'
import Slider_d from '../comp/slider_duzy'
import TytulPunkty from '../comp/tytul_punkty'




export default function Strategia() {

    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/kogym/3/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                        
                    ...GatsbyImageSharpFluid
                    }
                }
                },
                kompozycja: file(relativePath: { eq: "projekty/kogym/3/kompozycja@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }, 
                    
                    slider_d1: file(relativePath: { eq: "projekty/kogym/3/slider/1.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },
                    
                    slider_d2: file(relativePath: { eq: "projekty/kogym/3/slider/2.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },

                    slider_d3: file(relativePath: { eq: "projekty/kogym/3/slider/3.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },





             
            }
            `)

    const slider_d = [data.slider_d1.childImageSharp.fluid, data.slider_d2.childImageSharp.fluid, data.slider_d3.childImageSharp.fluid]     
    
    return(
        <>

      

        <Rozdzial tytul="DIGITAL DESIGN"  tekst="" nr="03." img={data.okladka.childImageSharp.fluid} tekst="Mariusz jest świadomym zawodnikiem, bardzo aktywnie prowadzącym własną komunikację w mediach społecznościowych (zobacz więcej na profilach Mariusza - np. na Instagramie @mcieslinski). Naszym zadaniem było uzupełnienie komunikacji o podstawowe materiały informacyjne, zamieszczane głównie na profilu Facebook szkoły oraz na Instastories na profilu Mariusza. ">
        <TytulPunkty tekst="W ramach projektu stworzyliśmy materiały mediowe, które pomogą  ekipie Mariusza w podstawowej obecności w mediach społecznościowych. Reszta contentu Mariusza tworzona jest real-life - więcej na Instagramie @mcieslinski." />

        <Punkt tytul="MATERIAŁY GRAFICZNE" tekst="Wszystkie stworzone materiały wpisują się w założenia opracowanego systemu identyfikacji wizualnej. Dzięki charakterystycznym rozwiązaniom pozostają widoczne i spójne, co pomaga budować popularność klubu i pozytywnie wpływa na prowadzone działania sprzedażowe oraz CSR’owe marki KO Gym." />

        <Img fluid={data.kompozycja.childImageSharp.fluid} />

        <Slider_d foto={slider_d} />

       
        

        

        
        
        </Rozdzial>
        
        </>
    )
}