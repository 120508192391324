import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import TransitionLink from 'gatsby-plugin-transition-link'
import React, { useRef } from 'react'
import { InView } from 'react-intersection-observer'
import Slick from "react-slick"
import "slick-carousel/slick/slick.css"
import Formularz from '../../components/formularz/formularz'
import Intro from '../../components/projekty/comp/projekt_intro'
import Inne from '../../components/projekty/inne'
import Identyfikacja from '../../components/projekty/kogym/1'
import Print from '../../components/projekty/kogym/2'
import DigitalDesign from '../../components/projekty/kogym/3'
import Fotografia from '../../components/projekty/kogym/4'
import Ocena from '../../components/projekty/OcenaProjektu'
import Seo from '../../components/seo.js'
import Slider from '../../components/slider/slider'
import back from '../../images/ikony/goback.svg'
import oko from '../../images/ikony/oko.svg'
import paluch from '../../images/ikony/paluch.svg'
import strzalka from '../../images/ikony/strzalka.svg'
import okladka from '../../images/projekty/kogym/okladka@2x.jpg'
import style from './projekt.module.css'







let title = "KO GYM"

export default function Betard() {
    // polyfill();
    const slider = useRef(null)
    const wrapper = useRef(null)

    const r1 = useRef(null)
    const r2 = useRef(null)
    const r3 = useRef(null)
    const r4 = useRef(null)


    useScrollPosition(
        ({ prevPos, currPos }) => {
        console.log(currPos.x)
        console.log(currPos.y)
        },
        [],
        [wrapper],
        false,
        300,
    )


    const data = useStaticQuery(graphql`
    query {
        okladka: file(relativePath: { eq: "projekty/kogym/okladka@2x.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
            }
        }
        },
        okladka2: file(relativePath: { eq: "projekty/kogym/okladka2@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid
                }
            }
            },

        
    }
    `)

        


    




        let settingss = {
            dots: false,
            adaptiveHeight: true,
            infinite: false,
            arrows: false,
            centerMode: false,
            speed: 300,
            slidesToShow: 3,
            variableWidth: true, 
          }    



    return (    
        <>
        <div style={{
            height: "calc(100vh - 60px)",
            overflow: "y-scroll",
        }}
        ref={wrapper}>
            <div id="projekt-bread" className="projekt-aktywny projekt-header">
                <div className="lista-item">
                    <TransitionLink style={{
                        left: '0',
                        top: '0',
                        position:'absolute',
                    }} to="/projekty">
                        <img className="projekt-header-cofnij" src={back} />
                    </TransitionLink>
                    <h3>{title}</h3>
                    <div className="projekt-zajawka">
                        <img src={oko} />
                        <span className="przycisk">2020</span>
                        <div className="projekt-zajawka-kategorie">
                            <span className="przycisk">identyfikacja wizualna</span>
                            <span className="przycisk">print</span>
                            <span className="przycisk">digital design</span>
                            <span className="przycisk">fotografia</span>

                        </div>
                        
                        <p>Niewielka realizacja dla szkoły sportów walki w Świebodzicach, prowadzonej przez czempiona nad czempionami, Mariusza Cieślińskiego, legendy kick-boxingu i trenera Betard Sparty Wrocław.</p>
                        <span 
                        style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}
                        className="przycisk"><img src={paluch} style={{marginRight: '10px'}}/>ZOBACZ PROJEKT</span>
                    </div>

                    { <div className="bread-dodatkowe slider-rozdzialy">
                    <div className="rozdzialy-maska"></div>
                    <Slick  ref={slider} className="rozdzial-slider" {...settingss}>
                    
                        <span onClick={
                            () => { r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(0);}}
                            className="przycisk">01. IDENTYFIKACJA WIZUALNA</span>
                        <span onClick={
                            () => { r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(1);}}
                        className="przycisk">02. PRINT</span>  
                        <span onClick={
                            () => { r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(2);}} 
                       className="przycisk">03. DIGITAL DESIGN</span>  
                        <span onClick={
                            () => { r4.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(3);}}
                        className="przycisk">04. FOTOGRAFIA</span>  
                       
                        
                    </Slick>
                    <div className="rozdzialy-maska"></div>
                    </div>}

                </div>

            </div>


            <Seo title={title} description="Niewielka realizacja dla szkoły sportów walki w Świebodzicach, prowadzonej przez czempiona nad czempionów, Mariusza Cieślińskiego, legendy kick-boxingu i trenera Betard Sparty Wrocław." image={okladka} />
            <div className="podstrona-wrapper projekt-padding">

            <Img style={{width: '100%'}} fluid={data.okladka.childImageSharp.fluid} />

            <Intro tytul="KO Gym - projekt identyfikacji wizualnej" tekst="Niewielka realizacja dla szkoły sportów walki w Świebodzicach, prowadzonej przez czempiona nad czempionów, Mariusza Cieślińskiego, legendy kick-boxingu i trenera Betard Sparty Wrocław." tekst1="Dwunastokrotny Mistrz Świata i pięciokrotny mistrz Europy w formule kick-boxing / muai thai. Trener przygotowania fizycznego zawodników Betard Sparty Wrocław. Indywidualny trener Macieja Janowskiego." tekst2="Szkoła KO Gym to prowadzony przez Mariusza Cieślińskiego ośrodek treningowy dla dzieci, młodzieży, amatorów i profesjonalistów w Świebodzicach. " />

            <Img style={{width: '100%'}} fluid={data.okladka2.childImageSharp.fluid} />








            <div className={style.architektura}>
                <h3>ARCHITEKTURA PROJEKTU</h3>
                <div>

               
                        <div onClick={() => r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   } data-rozdzial={1}>
                            <span className="przycisk">
                                01.IDENTYFIKACJA WIZUALNA
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={2}>
                            <span className="przycisk">
                                02. PRINT
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={3}>
                            <span className="przycisk">
                                03. DIGITAL DESIGN
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>
                        
                        <div onClick={() => r4.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={4}>
                            <span className="przycisk">
                                04. FOTOGRAFIA
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                       


           
                </div>
            </div>
            </div>


            <InView  as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('0')}>
            <div ref={r1}><Identyfikacja  /></div>
            </InView>

            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('1')}>
            <div ref={r2}><Print /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('2')}>
            <div ref={r3}><DigitalDesign  /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('3')}>
            <div ref={r4}><Fotografia /></div>
            </InView>
         
           

            <div className="podstrona-wrapper">
            <Ocena tekst='"Współpraca z Odra Studio to gwarancja profesjonalizmu, ale też olbrzymiej dawki kreatywności, dzięki niesamowitemu zespołowi specjalistów. Natomiast same działania Odra Studio na linii Red Bull x Maciej Janowski są stawiane przez nas jako best practice dla innych zawodników z naszego portfolio. Odra Studio wzorowo wywiązuje się z zleconych zadań dbając przy tym o najwyższą jakość usług i zachowanie unikalnej relacji i polecamy usługi wszystkim, którzy podobnie jak by cenią sobie współpracę na najwyższym poziomie."' firma="Athletes’ Manager, Red Bull" imie="Marek Krynicki" />

            <Slider  uslugi={true} />
            </div>

            <Inne omin="KO GYM" />


            


            <Formularz />
            </div>
      
         
        </> 
    )
}