import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Komp_zdjecie2 from '../comp/kol_tekst'
import Komp_zdjecie from '../comp/komp_2kol_tekst_foto'
import Punkt from '../comp/punkt_flex'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'





export default function Strategia() {

    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/kogym/2/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                        
                    ...GatsbyImageSharpFluid
                    }
                }
                },
                komp1: file(relativePath: { eq: "projekty/kogym/2/komp1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },
                komp2: file(relativePath: { eq: "projekty/kogym/2/komp2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },
                koszulki: file(relativePath: { eq: "projekty/kogym/2/koszulki@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },  
                    
                koszulka: file(relativePath: { eq: "projekty/kogym/2/koszulka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },
                    banery: file(relativePath: { eq: "projekty/kogym/2/banery@2x.jpg" },
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                        childImageSharp {
                            fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        },    
                    komp4: file(relativePath: { eq: "projekty/kogym/2/komp4@2x.jpg" },
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                        childImageSharp {
                            fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        }, 
                        komp5: file(relativePath: { eq: "projekty/kogym/2/komp5@2x.jpg" },
                        sourceInstanceName: {
                            eq: "images"
                          }) {
                            childImageSharp {
                                fluid( maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                            },             
            }
            `)
    
    return(
        <>

      

        <Rozdzial tytul="PRINT" invert={true} tekst="" nr="02." img={data.okladka.childImageSharp.fluid} tekst="Materiały drukowane stanowią ważny element lokalnej tożsamości i działań promocyjnych realizowanych na terenie Świebodzic i prowadzonymi we współpracy z lokalnymi władzami. W ramach druku stworzyliśmy plakaty, banery, podstawowe druki akcydensowe, odzież, zestawy naklejek i projekty ulotek - wszystko dystrybuowane wśród lokalnej społeczności.">
        <TytulPunkty tekst="Materiały drukowane oparte są na wizerunku Mariusza Cieślińskiego, w otoczeniu charakterystycznej typografii i symboliki przywodzącej na myśl stare gry video o tematyce sportów walki." />

        <Komp_zdjecie 
        img1={data.komp2.childImageSharp.fluid} 
        img2={data.komp1.childImageSharp.fluid} 
         />

        <Punkt tytul="ODZIEŻ" tekst="Istotnym elementem współpracy ze sportowcami są gadżety reklamowe, w tym elementy odzieży. Dla szkoły KO Gym stworzyliśmy projekty koszulek dla kursantów, zawodników oraz koszulkę trenera." />

        <Img fluid={data.koszulki.childImageSharp.fluid} />

        <Komp_zdjecie2
        invert={true}
        img={data.koszulka.childImageSharp.fluid} tytul="SPÓJNOŚĆ" tekst="Wszystkie stworzone materiały zachowują  spójny charakter, niezależnie od nośników i ich przeznaczenia. "/> 

            <Komp_zdjecie 
    
        img1={data.komp4.childImageSharp.fluid} 
        img2={data.komp5.childImageSharp.fluid} 
      
        tytul1="AKCYDENSY"
        tekst1="Akcydensy to podstawowe druki biznesowe wynikające z systemu identyfikacji marki. Poniżej zobaczysz przykłady papierów firmowych i wizytówek stworzone dla KO Gym."
         />

        <Punkt tytul="BANERY REKLAMOWE" tekst="Ze względu na ekspozycję szkoły w lokalnych miejscach partnerskich, podczas wydarzeń oraz w samej sali treningowej, jednym z istotnych elementów komunikacyjnych były banery reklamowe. Stworzyliśmy kilka wersji banerów na różne okazje i wymagane formaty. Przykładowe rozwiązania poniżej - zobacz." />

        <Img fluid={data.banery.childImageSharp.fluid} />
        

        

        
        
        </Rozdzial>
        
        </>
    )
}