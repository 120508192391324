import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Komp_zdjecie2 from '../comp/kol_tekst'
import Komp_zdjecie from '../comp/komp_2kol_tekst_foto'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'



export default function Research() {
    const data = useStaticQuery(graphql`
            query {
             
                wideo: file(relativePath: { eq: "projekty/kogym/1/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( quality: 75) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },


                etymologia: file(relativePath: { eq: "projekty/beefit/1/etymologia@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },
                logo: file(relativePath: { eq: "projekty/beefit/1/logo@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }      
                
                 ruch: file(relativePath: { eq: "projekty/beefit/1/ruch@2x.jpg" },
                 sourceInstanceName: {
                     eq: "images"
                   }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },  
                    komp1: file(relativePath: { eq: "projekty/kogym/1/komp1@2x.jpg" },
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                        childImageSharp {
                            fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        }, 
                        
            komp2: file(relativePath: { eq: "projekty/kogym/1/komp2@2x.jpg" },
            sourceInstanceName: {
                eq: "images"
              }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                }, 
                komp3: file(relativePath: { eq: "projekty/kogym/1/komp3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }, 
                    kop: file(relativePath: { eq: "projekty/kogym/1/kop@2x.jpg" }) {
                        childImageSharp {
                            fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        },        

               
                            
                            brandbook: file(relativePath: { eq: "projekty/kogym/1/brandbook@2x.jpg" }) {
                                childImageSharp {
                                    fluid( maxWidth: 2000, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                                },
                                             
            }
            `)
    return(
        <>

        <Rozdzial tytul="IDENTYFIKACJA WIZUALNA" tekst="Niewielka szkoła o ogromym sercu. Wymagała wprowadzenia niewielkich, nowoczesnych rozwiązań brandingowych, by ujednolicić wizerunek i usprawnić produkcję materiałów promocyjnych. Zobacz naszą realizację dla KO Gym w Świebodzicach!" nr="01." img={data.wideo.childImageSharp.fluid}>
        
        <TytulPunkty tekst="Projekt identyfikacji wizualnej dla szkoły walki w Świebodzinie, prowadzonej przez legendę kick-boxingu Mariusza Cieślińskiego. Głównym motywem jest liternictwo KO, wynikające z nazwy szkoły." />

        <Komp_zdjecie 
        img1={data.komp2.childImageSharp.fluid} 
        img2={data.komp1.childImageSharp.fluid} 
         />

        <Komp_zdjecie2
        invert={true}
        img={data.komp3.childImageSharp.fluid} tytul="DODATKOWE ELEMENTY" tekst="Liternictwo zostało uzupełnione fotografią , patternami i dynamicznymi symbolami, budującymi energię  projektu."/> 

        <Img fluid={data.kop.childImageSharp.fluid} />

        <Komp_zdjecie2
        img={data.brandbook.childImageSharp.fluid} tytul="Brandbook" tekst="Nawet najmniejsze projekty wymagają merytorycznego opisu, dla zachowania spójności i zrozumienia założeń projektowych. Dla KO Tym stworzyliśmy niewielki brandbook z elementami księgi znaku - to standardowa procedura dla wszystkich realizacji brandingowych tworzonych przez nasze studio."/> 


        <Komp_zdjecie 
        img1={data.komp2.childImageSharp.fluid} 
        img2={data.komp1.childImageSharp.fluid} 
         />
        

        </Rozdzial>
        
        </>
    )
}