import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import style from './slider_duzy.module.css'
import Img from "gatsby-image"
import stylisz from './fixes.css'


export default function SliderInfo(props)  {
    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1.4,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: false,
        responsive: [
          
          {
              breakpoint: 1025,
              settings: {
                slidesToShow: 1.4,
                variableWidth: false,
    
        
              }
            },
  
      ],
      };

    if(props.wideo) {

    }
    else {
      return (
        <div className={style.wrapper + " slider_duzy"}>
            <Slider {...settings}>
            {props.foto.map((e, i) => (
                <Img className={style.fotka} fluid={e} />
              ))}
            </Slider>
        </div>
    )
    }
    
}